<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="userData"
      pills
    >
      <!-- Tab: Information -->
        <user-edit-tab-information :data="userData" class="mt-2 pt-75" />
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import {ISP_PROFILE_INFO} from "@core/services/api";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    UserEditTabInformation,
  },
  data() {
    return {
      userData:null,
    }
  },
  methods:{
    getProfileInfo() {
        this.$http.get(process.env.VUE_APP_BASEURL+ ISP_PROFILE_INFO)
            .then(res => {
                this.userData = res.data.data
            }).catch(error => {
            console.log(error)
        })
    },
  },
  mounted(){
      this.getProfileInfo()
  }
}
</script>

<style>

</style>
