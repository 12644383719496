<template>
  <div>

  
    <validation-observer ref="simpleRules">
    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
          <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Personal Information
      </h4>
    </div>
      <b-row>
          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
              <b-form-group label="Name" label-for="full-name"  >
                  <b-form-input id="full-name" v-model="data.isp.name" placeholder="Enter Person Name" />
              </b-form-group>
          </b-col>
          <!-- Field: Email -->
          <b-col cols="12" md="4" >
              <b-form-group label="Email" label-for="email" >
                  <b-form-input id="email" v-model="data.isp.email" type="email" placeholder="Enter Person Email" />
              </b-form-group>
          </b-col>
         <!-- Field: Mobile -->
         <b-col cols="12" md="6" lg="4" >
          <b-form-group label="Mobile" label-for="mobile" >
            <b-form-input id="mobile" v-model="data.isp.mobile" readonly="readonly" />
          </b-form-group>
        </b-col>
      </b-row>
        <!-- Header: Personal Info -->
        <div class="d-flex mt-2">
            <feather-icon icon="BriefcaseIcon" size="19" />
            <h4 class="mb-0 ml-50">
                Company Information
            </h4>
        </div>

        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">
            <!-- Field: ISP Type -->
            <b-col cols="12" md="6" lg="4" >
                <b-form-group
                    label="ISP Licence Type"
                    label-for="isp_licence_type">
                    <validation-provider
                        #default="{ errors }"
                        name="ISP Licence Type">
                        <v-select
                            v-model="data.isp_licence_type"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="isp_licence_type_list"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="user-role"
                            placeholder="Select ISP Licence Type"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <!-- Field: Company name -->
            <b-col cols="12" md="6" lg="4">
                <b-form-group label="Company Name" label-for="company_name" >
                    <b-form-input id="company_name" v-model="data.company_name" placeholder="Enter Company Name" />
                </b-form-group>
            </b-col>
            <!-- Field: Company Mobile -->
            <b-col cols="12" md="6" lg="4" >
                <b-form-group label="Company Mobile" label-for="company_phone" >
                    <b-form-input id="company_phone" v-model="data.company_phone" placeholder="Enter Company Mobile Number" />
                </b-form-group>
            </b-col>
            <!-- Field: Company Email -->
            <b-col cols="12" md="6" lg="4" >
                <b-form-group label="Company Email" label-for="company_email" >
                    <b-form-input id="company_email" v-model="data.company_email" placeholder="Enter Company Email Address" />
                </b-form-group>
            </b-col>

            <!-- Field: Website -->
            <b-col cols="12" md="6" lg="4" >
                <b-form-group label="Website" label-for="website" >
                    <b-form-input id="website" v-model="data.web_url" />
                </b-form-group>
            </b-col>
        </b-row>
      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon icon="MapPinIcon" size="19" />
        <h4 class="mb-0 ml-50">
            Registered Company Address
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
          <b-col cols="12" md="6" lg="4" >
                  <b-form-group
                      label="Division"
                      label-for="division_id">
                      <validation-provider
                          #default="{ errors }"
                          name="Division Name" >
                          <v-select
                              v-on:input="getAllDistrictData($event)"
                              v-model="data.division_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="division_list"
                              :reduce="val => val.value"
                              :clearable="false"
                              input-id="user-role"
                              placeholder="Select Division"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
          </b-col>
          <!-- Form: Personal Info Form -->
          <b-col cols="12" md="6" lg="4" >
                  <b-form-group
                      label="District"
                      label-for="division_id">
                      <validation-provider
                          #default="{ errors }"
                          name="Division Name" >
                          <v-select
                              v-on:input="getAllUpazilaData($event)"
                              v-model="data.district_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="district_list"
                              :reduce="val => val.value"
                              :clearable="false"
                              input-id="user-role"
                              placeholder="Select District"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
          </b-col>
          <!-- Form: Personal Info Form -->
          <b-col cols="12" md="6" lg="4" >
              <b-form-group
                  label="Upazila"
                  label-for="division_id">
                  <validation-provider
                      #default="{ errors }"
                      name="Division Name" >
                      <v-select
                          v-model="data.upazila_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="upazila_list"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="user-role"
                          placeholder="Select Upazila"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
          </b-col>
        <!-- Field: Address Company -->
        <b-col cols="12" md="6" lg="4" >
          <b-form-group
            label="Company Address" label-for="company_address" >
            <b-form-input id="company_address" v-model="data.company_address" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click.prevent="validationForm"
          >
            Save Changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
      </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import {DISTRICT_LIST, DIVISION_LIST, UPAZILA_LIST, ISP_LICENCE_LIST} from "@core/services/api";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import  Mixin  from "@core/services/mixin";
import router from "@/router";
import {checkRes} from "@core/services/helper";

export default {
    mixins:[
        Mixin
    ],
    components: {
    BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton, ValidationProvider,ValidationObserver,
  },
    data(){
      return {
          division_list:[],
          district_list:[],
          upazila_list:[],
          isp_licence_type_list:[],
          user:null,
      }
    },
    props: {
        data: {
            default: () => [],
        },
    },
    methods:{
        getAllIspLicenceData() {
            this.$http.get(process.env.VUE_APP_BASEURL+  ISP_LICENCE_LIST)
                .then(res => {
                    this.isp_licence_type_list = res.data.data
                }).catch(error => {
                if (error.response.status === 404) {
                    return false
                }
            })
        },
        getAllDivisionData() {
            this.$http.get(process.env.VUE_APP_BASEURL+  DIVISION_LIST)
                .then(res => {
                    this.division_list = res.data.data
                }).catch(error => {
                    return false
            })
        },
        getAllDistrictData(event) {
            if (event){
                this.data.district_id = null
            }
            this.$http.get(process.env.VUE_APP_BASEURL +  DISTRICT_LIST + (event ? event : this.data.division_id))
                .then(res => {
                    this.district_list = res.data.data
                }).catch(error => {
                return false
            })
        }
        ,
        getAllUpazilaData(event) {
            if (event){
                this.data.upazila_id = null
            }
            this.$http.get(process.env.VUE_APP_BASEURL+  UPAZILA_LIST + (event ? event : this.data.district_id))
                .then(res => {
                    this.upazila_list = res.data.data
                }).catch(error => {
                    return false
            })
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                   useJwt.updateIspProfileInfo({
                       name: this.data.isp.name ?? null,
                       email: this.data.isp.email ?? null,
                       division_id: this.data.division_id,
                       district_id: this.data.district_id,
                       upazila_id: this.data.upazila_id,
                       company_name: this.data.company_name,
                       company_email: this.data.company_email,
                       company_phone: this.data.company_phone,
                       company_address: this.data.company_address,
                       web_url: this.data.web_url,
                       isp_licence_type: this.data.isp_licence_type,
                    }).then(response => {
                       if (checkRes(response.status) && response.data.data) {
                           this.$router.push({ name: 'update-profile' })
                            .catch(error => {
                                this.toastMessage('success','Profile Info',response)
                            })
                       }else {
                           this.$router.push({ name: 'update-profile' }).then(() => {
                               this.toastMessage('warning','Profile Info',response)
                           }).catch(error => {
                               this.toastMessage('danger','Profile Info',response)
                           })
                       }
                    })
                }
            })
        },
    },
    mounted() {
        this.getAllIspLicenceData()
        this.getAllDivisionData()
        this.getAllDistrictData()
        this.getAllUpazilaData()
    },
    computed: {
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
